import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from "gatsby"

//Import Assets
import Instagram from '../../images/instagram.svg'
import Facebook from '../../images/facebook.svg'
import Youtube from '../../images/youtube.svg'
import LogoFooter from '../../images/footer/logo-footer-das-ohr-with-label.png'

function Footer() {
    return (
        <div className="main">
            <div className="footer-container">
                <div className="footer-column">
                    <img src={LogoFooter} alt="Das Ohr By Olga Ribler"></img>
                </div>
                <div className="footer-column">
                    <h3>Piercing</h3>
                    <h4><AnchorLink to="/#gratis">Gratis Ohr piercen</AnchorLink></h4>
                    <h4><AnchorLink to="/#termin">Termin für Piercing</AnchorLink></h4>
                    <h4><a href="https://www.byolgaribler.com/produkt-kategorie/piercings/gold-piercings/">Piercing Online Shop</a></h4>
                </div>
                <div className="footer-column">
                    <h3>Rechtliches</h3>
                    <h4><Link to="/agb">AGB</Link></h4>
                    <h4><Link to="/datenschutz">Datenschutz</Link></h4>
                    <h4><Link to="/impressum">Impressum</Link></h4>
                </div>
                <div className="footer-column">
                    <div className="footer-social">
                    <a target="_blank" href="https://www.youtube.com/channel/UCN2P6Eqz4qH-B03YGq66m9A">
                            <Youtube alt="Das Ohr auf Youtube" />
                        </a>
                        <a target="_blank" href="https://www.instagram.com/olgaribler_ch/">
                            <Instagram alt="Das Ohr auf Instagram"/>
                        </a>
                        <a target="_blank" href="https://www.facebook.com/pg/byolgaribler/posts/">
                            <Facebook alt="Das Ohr auf Facebook" />
                        </a>
                    </div>
                    <h4><a href="mailto:info@dasohr.shop">info@dasohr.shop</a></h4>
                    <h4><a href="tel:0442526252">044 252 62 52</a></h4>
                    <h4>
                        <p>Studio Brunngasse 3</p>
                        <p>Niederdorf</p>
                        <p>Zürich</p>
                    </h4>
                </div>
            </div>
        </div>
    )
}

export default Footer
