import React, { useState } from 'react';
import { Link } from "gatsby"
import { slide as Menu } from 'react-burger-menu'
import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links";


//Import Assets
import LogoHeader from '../../images/header/initials-only-logo-das-ohr-v2.png'



export default () => {

  const [menuState, setMenuOpen] = useState({menuOpen: false});

   // This keeps the state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  // insert onStateChange at SideMenu -> Menu: onStateChange={state => handleStateChange(state)}
  const handleStateChange = state => {
    setMenuOpen(state.isOpen)
  }

  const closeMenu = () => {
    setMenuOpen({menuOpen: false})
  }


  return (
    <>
        <TopNav className="sticky-top">

            <Link to="/">
                <Logo src={LogoHeader} alt="Das Ohr Logo" />
            </Link>
            
            <NavItems className="menu-items">
              <AnchorLink to="/#story">Unsere Geschichte</AnchorLink>
              <AnchorLink to="/#gratis">Gratis Piercing</AnchorLink>
              <AnchorLink to="/#swissmade">Schweizer Technologie</AnchorLink>
              <AnchorLink to="/#wartezeit">Keine Wartezeit</AnchorLink>
              <AnchorLink to="/#kids">Kids</AnchorLink>
              <a href="https://www.byolgaribler.com/produkt-kategorie/piercings/gold-piercings/">Piercing Online Shop</a>
            </NavItems>


            <SideMenu>
              <Menu right isOpen={ menuState.menuOpen } onStateChange={state => handleStateChange(state)}>
                <AnchorLink to="/#story" onAnchorLinkClick={() => closeMenu()}>Unsere Geschichte</AnchorLink>
                <AnchorLink to="/#gratis" onAnchorLinkClick={() => closeMenu()}>Gratis Piercing</AnchorLink>
                <AnchorLink to="/#swissmade" onAnchorLinkClick={() => closeMenu()}>Schweizer Technologie</AnchorLink>
                <AnchorLink to="/#wartezeit" onAnchorLinkClick={() => closeMenu()}>Keine Wartezeit</AnchorLink>
                <AnchorLink to="/#kids" onAnchorLinkClick={() => closeMenu()}>Kids</AnchorLink>
                <a onClick={() => closeMenu()} href="https://www.byolgaribler.com/produkt-kategorie/piercings/gold-piercings/">Piercing Online Shop</a>
              </Menu>
            </SideMenu>

        </TopNav>
    </>

)}

const Logo = styled.img`
width: 42px;
height: auto;
padding-left: 36px;
@media(max-width: 480px){
  width: 38px;
  padding-left: 16px;
}
`;

const TopNav = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: flex-start;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #EFE5DF95;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1101;
  top: 0;
  height: 80px;
  align-items: center;
  /*box-shadow: 0px 1px 8px #19191930;*/
  text-decoration: none;
  position: fixed;
  @media(max-width: 990px) {
    position: fixed;
    height: 64px;
  }
`

const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  line-height: 1px;
  padding: 0 32px;
  font-weight: 700;
  a {
    color: #191919;
    text-align: center;
    padding: 1em;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: 600;
   /* transition: ease-in .2s;*/
   display: inline-block;
   position: relative;
    &::after {
      content: '';
	    position: absolute;
	    width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: #131313;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover::after{
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
  .menu-item-shop{
    border-radius: 30px;
    height: 10px;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #fff;
    background: #E66501;
    line-height: 1.5em;
    font-weight: 700;
    &:hover{
      color: #fff;
      background-color: #FF6F00;
      transition: ease-in .3s;
    }
  }
  .menu-item-kontakt-mob{
    border: solid 1px #283c86;
    border-radius: 30px;
    height: 10px;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #fff;
    background: #283c86;
  }
  @media(max-width: 989px) {
    display: none;
  }
`

const SideMenu =  styled.div`
    /* Individual item */
    .bm-item {
      display: block;
      /* Our sidebar item styling */
      text-decoration: none;
      margin-bottom: 32px;
      color: #131313;
      outline: none;
      transition: color 0.2s;
    }
    /* Change color on hover */
    .bm-item:hover {
      color: #CAA384;
    }
    /* The rest copied directly from react-burger-menu docs */
    /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed;
      width: 28px;
      height: 20px;
      right: 16px;
      top: 22px;
    }
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #000;
    }
    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 26px;
      width: 26px;
      top: -13px !important;
      right: 16px !important;
    }
    /* Color/shape of close button cross */
    .bm-cross {
      background: #000;
      height: 24px !important;
      width: 3px !important;
      /*top: -24px !important;
      left: -16px !important;*/
    }


    /*Mobile Menu Wrapper (Background) */
    .bm-menu-wrap{
      
    }

    /* General sidebar styles */
    .bm-menu {
      background: #f3f3f3;
      padding: 5em 1.5em 0;
      font-size: 1.4em;
      margin-top: -32px;
    }
    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }
    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
    }
    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.5);
      top: 0%;
      left: 0%;
    }
    @media(min-width: 990px) {
        display: none;
      }
`