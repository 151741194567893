
import * as React from "react"
import PropTypes from "prop-types"
import Footer from "./Footer/Footer";
import Navbar from "./Header/Navbar";
import CookieConsent from 'react-cookie-consent';
import { Link } from "gatsby"
import "../styles/App.scss";

const Layout = ({ children }) => {

  return (
    <>
        <Navbar />
        <main>{children}</main>
        <CookieConsent
          location="bottom"
          buttonText="OK"
          declineButtonText="Verwerfen"
          expires={365}
          cookieName="gatsby-gdpr-google-analytics"
          buttonClasses="cc-buttonClasses"
          buttonWrapperClasses="cc-buttonWrapper"
          declineButtonClasses="cc-declineButton"
        >
            Wir verwenden Cookies, um Dienstleistungen auf unserer Seite zu ermöglichen und die Nutzerfreundlichkeit der Internetseite zu verbessern. <Link to="/datenschutz" className="link-datenschutz">Mehr erfahren</Link>
        </CookieConsent>      
        <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
